.legend {
  border-radius: 5px;
  font-size: 10px;
  margin: 10px 10px 0 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 220px;
}

.legend a {
  color: inherit;
}

.label {
  font-weight: bold;
  margin: 0;
}

.label + .label {
  margin: 5px 0 0;
}

.explanation {
  display: none;
}

.gradient {
  color: #fff;
  height: 20px;
  padding: 0 2.5px;
  margin: 5px 0;
  transition: background 100ms;
}

.gradientCases {
  background: rgb(3, 252, 219);
  background: linear-gradient(
    90deg,
    rgba(137, 73, 204, 1) 0%,
    rgba(235, 10, 111, 1) 100%
  );
}

.gradientDeaths {
  background: rgb(255, 9, 6);
  background: linear-gradient(90deg, #700906 0%, #8a0906 50%, #ff0906 100%);
}

.legendBody,
.label,
.gradient {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.updatedAt {
  display: none;
  margin-bottom: 0;
}

@media only screen and (min-device-width: 550px) {
  .explanation {
    display: flex;
  }

  .updatedAt {
    display: block;
  }
}

@media only screen and (min-device-width: 1000px) {
  .legend {
    font-size: 12px;
    padding: 1rem;
    width: 275px;
  }
}
