.overlay {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}

.backdrop {
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 100;
}

.modal {
  background: #fff;
  border-radius: 5px;
  cursor: auto;
  font-size: 90%;
  max-height: 80vh;
  overflow: auto;
  padding: 2rem;
  position: relative;
  width: 80vw;
  max-width: 700px;
  z-index: 101;
}

.dark {
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
}

@media only screen and (min-device-width: 1000px) {
  .modal {
    font-size: 100%;
  }
}
