.dataSelector {
  background: rgba(255, 255, 255, 0.25);
  border-radius: 30px;
  font-size: 0.85em;
  display: inline-flex;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.dataSelector button {
  background: none;
  border: none;
  border-radius: 30px;
  color: inherit;
  cursor: pointer;
  font: inherit;
  font-weight: bold;
  letter-spacing: 0.0075em;
  padding: 0.5rem 0;
  position: relative;
  text-transform: uppercase;
  transition: color 100ms;
  width: 6em;
  -webkit-appearance: button;
  z-index: 1;
}

.dataSelector button.active {
  color: #5cecff;
}

.dataSelector button:hover,
.dataSelector button:focus {
  color: #5cecff;
  outline: none;
}

.selected {
  background: rgba(0, 0, 0, 0.75);
  border-radius: 30px;
  border: 1px solid #5cecff;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 50%;
  z-index: 1;
}
