html {
  background-color: rgb(36, 36, 36);
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #333;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

h1 {
  margin-top: 0;
}

h2 {
  font-size: 1.25em;
  margin-top: 0;
}

.btn {
  font: inherit;
  font-weight: bold;
  background: rgb(88, 42, 110);
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  padding: 1rem;
  text-decoration: none;
}

.icon-btn {
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  color: inherit;
  cursor: pointer;
  display: flex;
  margin: -7px;
  padding: 7px;
  text-decoration: none;
}

.icon-btn:hover {
  background: rgba(0, 0, 0, 0.3);
}

.muted {
  color: #777;
  font-size: 90%;
}

a {
  color: rgb(88, 42, 110);
}

.mapboxgl-ctrl-attrib-inner {
  user-select: none;
}
