.panel {
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 10px;
}

@media only screen and (min-device-width: 1000px) {
  .panel {
    padding: 2rem;
  }
}
