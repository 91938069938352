.graph {
  align-items: center;
  display: flex;
}

.playButton {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  margin-bottom: 40px;
  padding: 0;
}
