.Popover {
  background: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  color: #fff;
  padding: 1rem;
  position: relative;
  width: 300px;
  z-index: 2;
}

.Header {
  align-items: flex-start;
  display: flex;
}

.Name {
  font-size: 16px;
  margin-bottom: 0;
}

.State {
  font-size: 13px;
  color: #aaa;
}

.Pop {
  font-size: 14px;
  text-align: right;
  margin: 0;
}

.Label {
  color: #aaa;
  display: block;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
}

.Stats {
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.Stat {
  text-align: center;
}

.Stat span {
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 5px;
}
