.toggle {
  background: rgba(255, 255, 255, 0.25);
  border-radius: 30px;
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-block;
  font-size: 0.85em;
  font-weight: bold;
  letter-spacing: 0.0075em;
  padding: 0.5rem;
  margin-left: 1rem;
  text-align: center;
  transition: color 100ms;
  text-transform: uppercase;
}

.toggle.active {
  background: rgba(0, 0, 0, 0.75);
  border-color: #5cecff;
}

.toggle.active,
.toggle:hover,
.toggle:focus {
  color: #5cecff;
}

.toggle.active:hover,
.toggle.active:focus {
  color: inherit;
}

.toggle:focus {
  outline: none;
}

.toggle input {
  display: none;
}
