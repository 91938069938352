.dataPanel {
  bottom: 40px;
  font-size: 10px;
  left: 0;
  position: absolute;
  user-select: none;
  width: 100%;
  z-index: 1;
}

.dataPanel h1 {
  margin-bottom: 0.25rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summaryBox {
  align-items: baseline;
  display: flex;
  flex-direction: column;
}

.totals {
  font-size: 1.5em;
  margin-bottom: 0.25rem;
  /* margin-left: 1rem; */
}

.controlsBox {
  text-align: right;
}

.controlsBox label {
  margin-top: 0.5rem;
}

@media only screen and (min-device-width: 550px) {
  .summaryBox {
    flex-direction: row;
  }

  .totals {
    font-size: 1.5em;
    margin: 0 0 0 1rem;
  }

  .controlsBox label {
    margin-top: 0;
  }
}

@media only screen and (min-device-width: 1000px) {
  .dataPanel {
    font-size: inherit;
    padding: 2rem;
    border-radius: 5px;
    bottom: 3rem;
    left: 2rem;
    width: 820px;
  }

  .dataPanel h1 {
    margin-bottom: 1rem;
  }
}
